
const DUR = 1.2;

let loc = window.location.pathname;
loc = (loc === '/') ? 'index' : loc.substr(1);



export let
    url = loc.split("/").join(),
    $win = $(window),
    $body = $('body'),
    $nav  = $('nav'),
    $nav_artwork  = $('.nav-artwork'),
    $main  = $('main'),
    $header = $('header'),
    $footer = $('footer'),
    $doc = $(document),
    $grid = $('.grid'),
    $btn_scrolltop = $('.btn-scrolltop'),
    $btn_scrolldown = $('.btn-scrolldown'),
    $griditem = $('.grid-item').remove(),
    $title = $('#artwork > .title>'),
    $bg = $('.bg-wrap'),

    // -------------------

    winpx = [$win.outerWidth(), $win.outerHeight()],
    vw = winpx[0],
    vh = winpx[1],
    // XS, SM, MD, LG
    SCREEN_SIZES= [576 , 768 , 992 , 1200 ],
    csrf = $('meta[name="csrf-token"]').attr('content'),
    date = new Date(),
    date_string =  date.toLocaleDateString('en-ZA').replaceAll('/', '-'),
    year =  date_string.split('-')[0],
    cursor_x = 0,
    is_sm = () =>{
        return (window.innerWidth <= SCREEN_SIZES[2]);
    },
    dur = (i) =>{
        return fib(DUR, i, false).toFixed(1);
    },
    $cols = is_sm() ?  $('.grid-col.col-b') : $('.grid-col'),
    ease = ['circ.out', 'expo.out', 'elastic.out(1,0.2)'],



    // fws = ws,
    // fhs = hs ,
    // persp = perspective,
    sb_body,
    sb_aw
;

export function set_sb_body(sb){
    sb_body  = sb;

}
export function set_sb_aw(sb){
    sb_aw  = sb;

}


export {fhs, perspective, fws, F} from "@/utils/f";
// export {sb_body, sb_aw} from "@/modules/smooth-scrollbars";
