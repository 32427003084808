import * as v from './vars.js' ;
import * as f from './f.js' ;
import {calc_xy_to_center_elem_to_coords, calc_xy_to_center_elem} from './geometry.js' ;
import {nav as animate, props, i_cover, durations, calc_degree_for_covers, ease} from './animations.js';

let $nav = $('nav');
let $social = $('.social-wrap');
let $squares = $('.square');
let timer;
let $square = $();
let $desc = $();
let $contact = $();
let $cover = $('.cover');
let is_link_activated = false;
let timeout;
let timeline = {in: 		gsap.timeline({}), out: 		gsap.timeline({})};
let count = 0;
let $descs = $('.link-desc').remove();
let $bg_video = $('#myVideo');


// $nav.on( "mousemove", ".square.active", animate.rotate_covers);
// $nav.on( "click", ".square.activated", click);
// $nav.on("mousemove", ".square", mousemove);
// $nav.on( "mouseleave", ".square.active", mouseleave);

 function click(e) {
	e.preventDefault();

	animate.click();

}
function mousemove(e) {
	let $el = $(this);

    if (!($square.is($el))) {
		clearTimeout(timeout);

		$square = $el.addClass('active');
		// $desc = $descs.eq($squares.index($square));

		timeout = setTimeout(() => {

			$cover.each((i, e) => {
				gsap.set(e, {
					// rotationY: [180, 180][i], 
					// 'background-image': 'url(' + $square.data('img') + ')',
				});

				let coords = calc_xy_to_center_elem($cover.eq(i), $square);
				let rotationY = (++count%2 === 0) ? [fib(360, -3), -fib(360, -3)][i] : [-fib(360, -2), fib(360, -2)][i];

				gsap.set(e, {
					scale: 1,
				});
				gsap.set(e, {
					x: '+=' + coords[0], 
					y: '+=' + coords[1], 
					scale: 1,
					// rotationY: rotationY,
				});
			});



			if (timeline.out.isActive()) {
				timeline.out.pause();
				timeline.out = gsap.timeline({});
			}
			timeline.in = animate.fade();

			is_link_activated = true;

		}, 300)
    }
}


function mouseleave(e) {
	clearTimeout(timeout);

	let o = {};


	if (is_link_activated) {

		if (timeline.in.isActive()) {
			timeline.in.pause();
			timeline.in = gsap.timeline({});
		}
		timeline.out = animate.original(() => {
			// $('.square:hover').trigger('mousemove');
		});

	}

	$square.removeClass('active activated');
	$square = $();
	$nav.removeClass('link-active');
}



export function init(){
	$contact = $('.contact');
	$cover = $('.cover');
	$squares = $('.square');
	let $last_square = $('.square').last();


	let soc_x = $last_square.outerWidth() + $last_square.offset().left - $('.social-wrap').outerWidth() - parseInt(v.$nav.find('.nav-bg-wrap').css('margin-right'), 10);
	let soc_y = $last_square.offset().top - $('.social-wrap').outerHeight();
	// gsap.set('.social-wrap', {top: soc_y,left: soc_x });
	// gsap.set('.frame', {height: v.win_px[1],width: v.win_px[0] });
	// gsap.set('.frame .top .right', {transform: 'skew('+fib(360, 2)+'deg)'});
	// gsap.set('.frame .top .left', {transform: 'skew('+fib(360, -2)+'deg)'});
	// gsap.set('.frame .bottom .right', {transform: 'skew('+fib(360, -2)+'deg)'});
	// gsap.set('.frame .bottom .left', {transform: 'skew('+fib(360, 2)+'deg)'});
	gsap.set(v.$body, {perspective: f.perspective});
	// gsap.set($cover.eq(0), {z: fib(f.perspective, -10)});
	// gsap.set($cover.eq(1), {z: fib(f.perspective, -4)});
	// gsap.set($cover, {rotationY: fib(360, -2)});
	gsap.set('nav', {perspective: f.perspective});

	// gsap.set('#myVideo', {z: -fib(f.perspective, -10)});
	
}

export {$squares, $square, $cover, $contact, $desc, $bg_video}