import {$footer, $header, dur, ease, fhs, url} from "@/vars";
import './bootstrap';
import {scrolltriggers} from "@/components/animations";
import {fws} from "@/utils/f";
import {gsap} from "gsap";
let routes = {};

routes.regex = {
    // edit_artwork: /^artwork,\d+,edit$/,
}


$('.spacer').css({height: $footer.outerHeight() +'px'});

routes.index = () => {
    scrolltriggers.index.header();
    scrolltriggers.index.section1();
    scrolltriggers.index.section2();
    scrolltriggers.index.section3();


}




routes['alternativ-gyogymodok'] = () => {
    let $card_wrap = $('#alternativ .card-wrap');
    let $contents = {
        biores: $('#biores-content').remove().find('.btn-x').click(showCards).end(),
        medicine: $('#medicine-content').remove().find('.btn-x').click(showCards).end(),
        gyogyaszat: $('#gyogyaszat-content').remove().find('.btn-x').click(showCards).end()

    }

    function showCards() {
        let tl = gsap.timeline();
        tl.to('#biores', {x: 0, opacity: 1, duration: dur(1), ease: ease[1]}, 0)
        tl.to('#medicine', {y: 0, opacity: 1, duration: dur(1), ease: ease[1]}, 0)
        tl.to('#gyogyaszat', {x: 0, opacity: 1, duration: dur(1), ease: ease[1]}, 0)
        tl.to('.content-wrap', {y: 0, opacity: 0, duration: dur(0), ease: ease[1], onComplete:()=>{$('.content-wrap').empty()}}, 0)
    }



$('#alternativ button').on('click', function () {
    let $this = $(this);
    if ($this.parent('#biores').length){
        hideCards();
        showContent('biores');
    }else if ($this.parent('#medicine').length){
        hideCards();
        showContent('medicine');
    }else if ($this.parent('#gyogyaszat').length){
        hideCards();
        showContent('gyogyaszat');
    }


    function hideCards() {

        let tl = gsap.timeline();
        tl.to('#biores', {x: -fws[5], opacity: 0, duration: dur(-1), ease: ease[1]}, 0)
        tl.to('#medicine', {y: -fhs[5], opacity: 0, duration: dur(-1), ease: ease[1]}, 0)
        tl.to('#gyogyaszat', {x: fws[5], opacity: 0, duration: dur(-1), ease: ease[1]}, 0)
        tl.to('.content-wrap', {y: -$('#card-wrap').outerHeight(), opacity: 1, duration: dur(-1), ease: ease[1]}, 0)
    }

    function showContent(which){
        console.log($contents[which])
        $card_wrap.append($contents[which]);
        $('.content-wrap').append($contents[which]);
    }



})}
routes.contact = () => {


}


routes['artwork,create'] = () => {

}

routes.edit_artwork= () => {

}



for (let route in routes.regex) {
    let regex = routes.regex[route];
    if (regex.test(url)) {
        routes[route]();
    }
}


routes[url] && routes[url]();

