import {calc_xy_to_center_elem_to_coords, calc_xy_to_center_elem} from './geometry.js' ;

// import {*} from './nav';
import {$bg_video, $square, $squares, $cover, init, $desc, $contact} from './nav';
import {$header, dur, fhs, fws, ease} from "@/vars";

const DUR = 1.4;



const DURS = [
	// fib(DUR, -3, false),
	// fib(DUR, -2, false),
	// fib(DUR, -1, false),
	// DUR,
	// fib(DUR, 1, false),
	// fib(DUR, 2, false),
	// fib(DUR, 3, false)
	];


for (let i = -3; i < 4; i++) {
    DURS.push(fib(DUR, i, false));
}
console.log('durations', DURS);
let scrolltriggers = {};

// let ease = ['power4.out', 'expo.out'];
let durations = (i) => {
	let duration = DUR;
	let base_index = DURS.indexOf(DUR);


	if (Number.isInteger(i)) {
		let target = i+base_index;

		if ((target <= 0)) {
			duration = DURS[0];
		}
		else if ((target > DURS.length-1)) {
			duration = DURS.last();
		}else{
			duration = DURS[target];

		}
	}

	return duration;

}


let i_cover = 0;
let nav = {};
let props;
let opt = {
	duration: durations(),
	ease:ease[0],
};

nav.click = function() {
	let tl = gsap.timeline({});

	tl.to($cover[0], {...opt, x: -a.fws[8]});
	tl.to($cover[1], {...opt, x: a.fws[8]}, 0);
}

nav.cover = function($el) {
	// let tl  = gsap.timeline({});

	let $wrap  = $el.parent();
	let coords = calc_xy_to_center_elem_to_coords($el, $square);
	gsap.set($wrap, {
		zIndex: -1,
		pointerEvents: 'none',
	});


	gsap.to($wrap, {
		duration: durations(),
		autoAlpha: 1,
		ease:'power4.out',
	});


	$el.find('.side-back > img').css(
		{
			width: props.width[i_cover],
			height: props.height[i_cover],
		})
	.attr('src', $square[0].dataset.img);



	let o =
	{
		// z:props.z[i_cover],
		y: coords[1],
		x: coords[0],
		rotationY:props.rotationY[i_cover],
		duration: props.duration[i_cover],
		zIndex: props.zindex[i_cover],
		ease:'power4.out'
	}

	i_cover++;



	return o;
}
nav.fade = function($el) {
	let x_of_$el = ($square.offset().left + ($square.outerWidth()/2));
	let y_of_$el = ($square.offset().top + ($square.outerHeight()/2));
	let tl = gsap.timeline({});



	$squares.each(	(i, e) => {
		if ($squares.eq(i).is($square)) return;

		tl.to(e, {
			opacity: 0,
			// scale: .9,
			duration: durations(-1), ease: ease[0]}, .02*i);
	}, 0);

	let tl_desc = gsap.timeline({});

	// tl_desc.fromTo($desc.find('li'),
	// {
	// 	autoAlpha: 0,
	// 	y: -a.fhs[5],
	// },
	// {
	// 	autoAlpha: 1,
	// 	duration: durations(-2),
	// 	y: 0,
	// 	stagger: durations(-4),
	// 	ease: ease[1]
	// }
	// , 0);
	tl.to($bg_video, {
		z: -fib(a.perspective, -2)
		// scale: .6
	}, 0);

	// tl.from('#logo-and-contact-wrap .logo > *, #logo-and-contact-wrap .contact > li', {
	// 	// y: '-=' - ($('#myVideo').offset().top  - $('#logo-and-contact-wrap .logo').offset().top),
	// 	// marginTop: '-=' + a.fhs[8],
	// 	y: 10,
	// 	stagger: .2,
	// 	// bottom: 'auto',
	// 	ease: ease[0],
	// 	duration: durations(-2),
	// 	// margin: 'none',
	// 	// scale: .6
	// }, 0);

	tl.fromTo('.bg.a', {
		// y: 0,
		opacity: 1,
		// bottom: 'auto',
		// width: a.fws[10],
		// scale: .6
		x: -$('.bg.a').outerWidth(),

	},{
		ease: ease[0],
		duration: durations(-1),

		opacity: 1,
		x: 0
	}, 0);
	tl.fromTo('.bg.b', {
		// y: a.fhs[7],
		opacity: 1,
		// bottom: 'auto',
		// width: a.fws[10],
		// scale: .6
		x: $('.bg.b').outerWidth(),

	},{
		ease: ease[0],
		duration: durations(-1),

		opacity: 1,
		x: 0
	}, 0);



	$cover.each((i,e) => {

		let coords = calc_xy_to_center_elem($cover.eq(i), $square);

		tl.fromTo(e, {
			// scale:.6,
			autoAlpha:1,
			top: ([0,1].rnd() === 0) ? a.fhs[9] : -a.fhs[9],
			left: ([0,1].rnd() === 0) ? a.fhs[9] : -a.fhs[9],
			// left: a.fws[8],

		},
		{
			z: (i===0) ? fib(a.perspective, -10) :fib(a.perspective, -4),
			// rotationY: [0, 0][i],
			autoAlpha:1,
			// duration: 6,
			// scale:1,

			top: coords[1],
			left: coords[0],
			duration: durations(-1),
			// z: [fib(a.perspective, -10), fib(a.perspective, -4)][i],
			ease: ease[0],
		}, fib(durations(), -3));
	})

	tl.to('#logo-and-contact-wrap', {
		// y: '-=' - ($('#myVideo').offset().top  - $('#logo-and-contact-wrap .logo').offset().top),
		marginTop: 0,
		// marginTop: '-=' + a.fhs[8],

		// bottom: 'auto',
		width: a.fws[10] * 1.3,
		border: 'none',
		ease: ease[1],
		duration: durations(-1),
		margin: 'none',
		z: fib(a.perspective, 26),
		// rotationX: -fib(360, -5),
		// scale: .6
	}, 0);

	tl.to('.effect', {
		// y: '-=' - ($('#myVideo').offset().top  - $('#logo-and-contact-wrap .logo').offset().top),
		// top: -a.fws[6],

		// bottom: 'auto',
		width: a.fws[7] ,
		// border: 'none',
		ease: ease[0],
		duration: durations(-1),
		// margin: 'none',
		// scale: .6
	}, 0);

	return tl;


}
nav.original = function(oncomplete) {
	let tl = gsap.timeline({onComplete: oncomplete});

	$squares.each(	(i, e) => {
		tl.to(e, {
			opacity: 1,
			x: 0,
			y: 0,
			duration: durations(-1), ease: ease[0]}, 0);

	}, 0);

	$cover.each((i,e) => {
		tl.to(e, {
			// rotationX: 180,
			// rotationY: [fib(360, -3), -fib(360, -3)][i],
			duration: durations(), ease: ease[0],
			autoAlpha:0,
			// scale:.6,
			z: 0,

		}, 0);
	})
	let tl_desc = gsap.timeline({onComplete: () => {
		// $desc.remove();
	}});

	// tl_desc.to($desc, {duration: durations(-2) ,autoAlpha: 0});
	tl.to($bg_video, {
		z: -fib(a.perspective, -10)
		// scale: .6
	}, 0);
	tl.to('#logo-and-contact-wrap', {
		// top: 0,
		marginTop: a.fhs[8],


		width: a.fws[10],
		// scale: .6
	}, 0);
	tl.to('.bg.a', {
		// y: 0,
		opacity: 1,
		x: -a.fws[10],
		// width: a.fws[10],
		// scale: .6
	}, 0);
	tl.to('body > .overlay.gradient', {
		opacity: 1,
		// scale: .6
	}, 0);
	tl.to('.bg.b', {
		// y: 0,
		opacity: 1,
		x: a.fws[10],
		// width: a.fws[10],
		// scale: .6
	}, 0);
	tl.to('body > .overlay.gradient', {
		opacity: 0,
		// scale: .6
	}, 0);


	tl.to('.effect', {
		// y: '-=' - ($('#myVideo').offset().top  - $('#logo-and-contact-wrap .logo').offset().top),
		// top: -a.fws[6],

		// bottom: 'auto',
		width: 0 ,
		// border: 'none',
		ease: ease[0],
		duration: durations(-1),
		// margin: 'none',
		// scale: .6
	}, 0);
	return tl;
}

nav.rotate_covers = function() {

	let tl= gsap.timeline({});

	let degree_a = 180 + fib(nav.fcts.calc_degree_for_covers(), -2);
	let degree_b = -180 + fib((nav.fcts.calc_degree_for_covers()), -3);



	tl
		.to($cover.eq(0), {rotationY: degree_a,
			ease: 'expo.out',
			duration: durations(-1),
			// delay: fib(durations(-3), -4)
		}, 0)
		.to($cover.eq(1), {rotationY: degree_b,
			ease: 'expo.out',
			duration: durations(-1),
			// delay: fib(durations(-3), -4)
		}, 0)

		// .to($desc, {rotationY: 0 + fib(nav.fcts.calc_degree_for_covers(), 0),
		// 	ease: 'expo.out',
		// 	duration: durations(-3),
		// 	// delay: fib(durations(-3), -4)
		// }, 0)

		// .to($contact, {rotationY: 0 + fib(nav.fcts.calc_degree_for_covers(), 0),
		// 	ease: 'expo.out',
		// 	duration: durations(-3),
		// 	// delay: fib(durations(-3), -4)
		// }, 0);

		// console.log('asd', degree_a, degree_b, nav.fcts.calc_degree_for_covers());

	return tl;
}



nav.fcts = {};
nav.fcts.calc_props_for_cover = function() {
	i_cover = 0;
	props = {
		$bigger: $cover.eq(0),
		// height: a.fhs.slice(7, 11).rnd_distinct_elem(2),
		// width: a.fws.slice(9, 11).rnd_distinct_elem(2),
		height: [a.fhs.slice(7, 11).last(), a.fhs.slice(7, 10).last()],
		width: [a.fws.slice(9, 11).last(), a.fws.slice(9, 10).last()],
		// width: a.fws.slice(9, 11).rnd_distinct_elem(2),
		zindex: [0, 1],
		rotationY: [
// -180,
// 180,
			-180 + fib(nav.fcts.calc_degree_for_covers(), -2),
			180 + fib(nav.fcts.calc_degree_for_covers(), -3)
			],
		duration: [durations(1), durations()],
		z: [0, fib(a.perspective, -5)],
	};


	// let is_larger = (props.height[0] * props.width[0]) < (props.height[1] * props.width[1]);




	// if (is_larger) {

	// 	for (let key in props ) {
	// 		if (['height', 'width'].has_elem(key)) continue;


	// 		if (props .hasOwnProperty(key) && Array.isArray(props[key])) {
	// 			props[key] = props[key].reverse();
	// 		}
	// 	}
	// 	props.$bigger = $cover.eq(1);
	// }



	return props;
}

nav.fcts.calc_degree_for_covers = function() {
	let range_of_degree = fib(360, -5)*2;
	let width = $square.outerWidth();
	let cursor_pos_x_on_square = a.cursor_x - $square.offset().left;
	let cursor_percent_on_square = (cursor_pos_x_on_square/width)*100;
	let degree = ((range_of_degree/100)*cursor_percent_on_square) - (range_of_degree/2);

	return degree;
}

let calc_degree_for_covers = nav.fcts.calc_degree_for_covers;


scrolltriggers.index = {

    header: function() {
        let sct =
            {
                trigger: $header,
                // WHEN I scroll from the top of the page. I want the animation to run immediately.
                // So how can I set a little bit of offset? so that it goes right away. when I scroll down.

                start: "0% " + $header.offset().top, // when the top of the trigger hits the top of the viewport
                // start: "top+="+$header.offset().top + " top", // when the top of the trigger hits the top of the viewport
                end: "100% 30%", // when the bottom of the trigger hits the top of the viewport
                scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                // markers: true,
            }

        let tl = gsap.timeline({});


        // Rotate yin yang
        tl.to('.yinyang', {
            opacity:0,
            rotation: 360,
            ease: 'linear', // linear movement
            duration: dur(3), // how long the tween lasts
        }, 0);


        // Close curtain
        tl.to('header > .overlay',
            {
                ease: 'linear', // linear movement
                // ease: ease[0],
                duration: dur(3),
                y: '0%'
            }, 0);


        ScrollTrigger.create({animation: tl, ...sct});
        return tl;

    },




};

// TODO: just trigger the tl. no need for scrolling to dictate progress.
// at a scroll location, trigger the tl. but the tl was activating when scrolled back
scrolltriggers.index.section1 = function() {
    let tl = gsap.timeline({immediateRender: true});
    tl.fromTo('#szolgaltatasok > div > *', {
            x: -fws[5],
            opacity: 0,
        },
        {
            x: 0,
            opacity: 1,
            ease: ease[0], // linear movement
            // duration: 20, // how long the tween lasts
            duration: dur(-1), // how long the tween lasts
            // stagger:.5,
            stagger: dur(-3),
        });

    let sct =
        {
            animation: tl,
            trigger: '#szolgaltatasok',
            start: "0% 43%", // when the top of the trigger hits the top of the viewport
            end: "0% 43%", // when the bottom of the trigger hits the top of the viewport
            // end: "top+="+fhs[9]+"px 61%", // when the bottom of the trigger hits the top of the viewport
            // scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // markers: true,
            // "onEnter",
            // "onLeave",
            // "onEnterBack",
            // "onLeaveBack".
            toggleActions: "restart none reverse none"

            // duration: '100px',
            // onEnter: () => {
            //     tl.play();
            // }
        }

    // let tl = gsap.timeline({});



    ScrollTrigger.create(sct);


}

scrolltriggers.index.section2 = function() {
    let sct =
        {
            trigger: '#about',
            start: "0% 43%", // when the top of the trigger hits the top of the viewport
            end: "0 43%", // when the bottom of the trigger hits the top of the viewport
            // end: "61% 98%", // when the bottom of the trigger hits the top of the viewport
            // scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // markers: true,
            // duration: 1,

            toggleActions: "restart none reverse none"

        }

    let tl = gsap.timeline({});


    $('#about > div, #about .btn').each((i, e) => {
        let tl_sub = gsap.timeline({});
        let y = (i%2 != 0) ? -1*fhs[6] : fhs[6];


        tl.fromTo(e, {
            y:y,
            opacity: 0,
        },
        {
            y: 0,
            opacity: 1,
            ease: ease[1], // linear movement
            duration: dur(-2), // how long the tween lasts
        });
        tl.add(tl_sub, 0);
        // tl.add(tl_sub, i* dur(-5));
        // tl.add(tl_sub, '-='+-dur(1));
    });

    ScrollTrigger.create({animation: tl, ...sct});


}


scrolltriggers.index.section3 = function () {


    // Array of element ids
    const elements = ["#csontkovacs", "#joga", "#masszazs"];


    elements.forEach(function (element) {
        // Create a timeline for the animation
        let tl = gsap.timeline({
            scrollTrigger: {
                // markers: true,

                trigger: element,
                start: "top top",
                end: "top bottom",
                endTrigger: '.spacer',
                // end: "+=100%",
                pin: true,
                pinSpacing: false,
                scrub: false, // This allows the animation to scrub (follow) the scrollbar position
            }
        });

        // // Add an animation to the timeline
        // tl.fromTo(element,
        //     {autoAlpha: 1,
        //         // scale: .8,
        //         // y:-100
        //     }, // Starting state
        //     {
        //         // scale: 1,
        //
        //         // y: 0, // Ending state
        //         autoAlpha: 1, // Ending state
        //         ease: ease[0], // Easing function
        //         duration: 1 // Duration of the animation
        //     }
        // );
    });












    // // Loop over the elements array
    // elements.forEach(function (element) {
    //     // Pin the element
    //     ScrollTrigger.create({
    //         snap: .1,
    //         trigger: element,
    //         pinSpacing: false,
    //         // scrub:1,
    //         start: "top top", // when the top of the trigger hits the top of the viewport
    //         end: "+=100%", // when the bottom of the trigger hits the top of the viewport
    //         pin: true, // pin the trigger element while the trigger is active
    //         // markers: true, // visualize the trigger start and end points
    //     });
    // });


//
//
//
//     // Pin the #csontkovacs element
//     ScrollTrigger.create({
//         trigger: "#csontkovacs",
//         start: "top top", // when the top of the trigger hits the top of the viewport
//         end: "+=100%", // when the bottom of the trigger hits the top of the viewport
//         pin: true, // pin the trigger element while the trigger is active
//         // markers: true, // visualize the trigger start and end points
//     });
//
// // Pin the #joga element
//     ScrollTrigger.create({
//         trigger: "#joga",
//         start: "top top", // when the top of the trigger hits the top of the viewport
//         end: "+=100%", // when the bottom of the trigger hits the top of the viewport
//         pin: true, // pin the trigger element while the trigger is active
//         // markers: true, // visualize the trigger start and end points
//     });
//
// // Pin the #masszazs element
//     ScrollTrigger.create({
//         trigger: "#masszazs",
//         start: "top top", // when the top of the trigger hits the top of the viewport
//         end: "+=100%", // when the bottom of the trigger hits the top of the viewport
//         pin: true, // pin the trigger element while the trigger is active
//         // markers: true, // visualize the trigger start and end points
//     });
}

    export {nav, props, i_cover, durations, calc_degree_for_covers, ease, scrolltriggers}
