let url = window.location.pathname;
    url = (url === '/') ? 'index' : url.substr(1);
    url = url.split("/").join();

let $win = $(window);
let win_px = [$win.outerWidth(), $win.outerHeight()];
let vw = win_px[0];
let vh = win_px[1];

let $body = $('body');
let $nav  = $('nav');
let $main  = $('main');
let $header = $('header');
let $doc = $(document);
let csrf = $('meta[name="csrf-token"]').attr('content');
let date = new Date();
let date_string =  date.toLocaleDateString('en-ZA').replaceAll('/', '-');
let year =  date_string.split('-')[0];
let cursor_x = 0;
let cursor_y = 0;

export {
	url, csrf, 
	date, date_string, year,
	$win, $body, $doc, $header, $main, $nav, 
	win_px,
} ;