function calc_xy_to_center_elem($elem_to_move, $elem_to_stay) 
    {
        let active_center_y = $elem_to_stay.offset().top + ($elem_to_stay.outerHeight()/2);
        let y = (active_center_y - $elem_to_move.offset().top) - $elem_to_move.outerHeight()/2;
        // let y = Math.abs(active_center_y - $elem_to_move.offset().top) - $elem_to_move.outerHeight()/2;



        let active_center_x = $elem_to_stay.offset().left + ($elem_to_stay.outerWidth()/2);
        let x = (active_center_x - $elem_to_move.offset().left) - $elem_to_move.outerWidth()/2;
        // let x = Math.abs(active_center_x - $elem_to_move.offset().left) - $elem_to_move.outerWidth()/2;


        return [x, y];

                
}
function calc_xy_to_center_elem_to_coords($el, $square) 
    {
        // let y = coords[1] + ($square.outerHeight()/2);



        let y = $square.data('y') + $square.data('height')/2;
        y = y - $el.offset().top - $el.outerHeight()/2;


        let x = $square.data('x') + $square.data('width')/2;
        x = x - $el.offset().left - $el.outerWidth()/2;

        // console.log('asd,', $square.offset().top);
        // console.log('asd,', $el.offset().top);

            // y = y - ($el.offset().top + $el.outerHeight()/2);



        // let x = $square.offset().left - $el.offset().left;
        // let x = coords[0] + ($square.data('width')/2) - $el.offset().left;
            // x = (x - $el.offset().left) - $el.outerWidth()/2;


            // x = (x - $el.offset().left) - $el.outerWidth()/2;

        return [x, y];

                
}

function do_they_overlap($a, $b)
{
    let ax = [$a.offset().left, $a.offset().left + $a.outerWidth()],
        ay = [$a.offset().top, $a.offset().top + $a.outerHeight()];

    let bx = [$b.offset().left, $b.offset().left + $b.outerWidth()],
        by = [$b.offset().top, $b.offset().top + $b.outerHeight()];

    let condition_a = (ax.is_between(bx[0]) || ax.is_between(bx[1]))  && (ay.is_between(by[0]) || ay.is_between(by[0]));
    let condition_b = (bx.is_between(ax[0]) || bx.is_between(ax[1]))  && (by.is_between(ay[0]) || by.is_between(ay[0]));

    return condition_a || condition_b;

}

function is_off_screen($elem){
    let screenwidth = $(window).outerWidth();
    let screenheight = $(window).outerHeight();
    let width = $elem.outerWidth();
    let height = $elem.outerHeight();
    let os_left = $elem.offset().left;
    let os_top = $elem.offset().top;
    let res = [false, false, false, false];

    if (os_top < 0)
        res[0] = true;
    if (os_left + width > screenwidth)
        res[1] = true;
    if (os_top+height > screenheight)
        res[2] = true;
    if (os_left < 0)
        res[3] = true;




    return res;

}



export {calc_xy_to_center_elem_to_coords, calc_xy_to_center_elem};