import {winpx} from "@/vars";
import {$body} from "./vars";

const F_DEPTH = 11;
const F       = 1.618;

let ws = [];
let hs = [];
let degrees = [fib(360, -1), fib(360, -2), fib(360, -3), fib(360, -4), fib(360, -5), fib(360, -6)];
let perspective;
//make a fibonacci sequnce based on window width and height
for (let i = -F_DEPTH; i < 0; i++)
{
    ws.push(fib(winpx[0], i));
    hs.push(fib(winpx[1], i));
}

 perspective = fib(hs[10], 4);


console.log('ws', ws);
console.log('hs', hs);
console.log('deg', degrees);

//should be stored as const since it doesn't matter what the val of x is
// 31.71606522953814
function calcSkewDeg()
{
    let x = vw
    let y = x * F;
    let d = y - x;
    let c = Math.sqrt(Math.pow(x, 2) + Math.pow(d, 2));
    let alpha = Math.asin(d/c);

    alpha = alpha * (180/Math.PI);

    return alpha;
}



export function margins(){
    let css = {};
    let margin_names = ['top', 'right', 'bottom', 'left'];

    for (let i = 0; i < 4; i++)
    {
        for (let j = 0; j < hs.length; j++)
        {
            let classname = '.m' + margin_names[i].charAt(0) + '-' + j;
            let propname = 'margin-' + margin_names[i];

            css[classname] = {};

            css[classname][propname] = (i%2) ? ws[j] : hs[j] ;
            css[classname][propname] += 'px' ;

            // negative
            classname = '.m' + margin_names[i].charAt(0) + '-n' + j;
            propname = 'margin-' + margin_names[i];

            css[classname] = {};

            css[classname][propname] = (i%2) ? -ws[j] : -hs[j] ;
            css[classname][propname] += 'px' ;


            classname = '.p' + margin_names[i].charAt(0) + '-' + j;
            propname = 'padding-' + margin_names[i];

            css[classname] = {};

            css[classname][propname] = (i%2) ? ws[j] : hs[j] ;
            css[classname][propname] += 'px' ;

        }
    }
    for (let j = 0; j < hs.length; j++)
    {
        let classname;
        let propname;

        classname = '.pa-' + j;
        propname = 'padding';
        css[classname] = {};

        css[classname][propname] = hs[j] + 'px '  +  ws[j] + 'px' ;

        classname = '.ps-' + j;
        css[classname] = {};

        css[classname]['padding-left'] = ws[j] + 'px';
        css[classname]['padding-right'] = ws[j] + 'px';

        classname = '.ptb-' + j;
        css[classname] = {};

        css[classname]['padding-top'] = hs[j] + 'px';
        css[classname]['padding-bottom'] = hs[j] + 'px';


        classname = '.ma-' + j;
        propname = 'margin';
        css[classname] = {};

        css[classname][propname] = hs[j] + 'px '  +  ws[j] + 'px' ;

        classname = '.ms-' + j;
        css[classname] = {};

        css[classname]['margin-left'] = ws[j] + 'px';
        css[classname]['margin-right'] = ws[j] + 'px';

        classname = '.mtb-' + j;
        css[classname] = {};

        css[classname]['margin-top'] = hs[j] + 'px';
        css[classname]['margin-bottom'] = hs[j] + 'px';

        // negative
        classname = '.ma-n' + j;
        propname = 'margin';
        css[classname] = {};

        css[classname][propname] = -hs[j] + 'px '  +  -ws[j] + 'px' ;

        classname = '.ms-n' + j;
        css[classname] = {};

        css[classname]['margin-left'] = -ws[j] + 'px';
        css[classname]['margin-right'] = -ws[j] + 'px';

        classname = '.mtb-n' + j;
        css[classname] = {};

        css[classname]['margin-top'] = -hs[j] + 'px';
        css[classname]['margin-bottom'] = -hs[j] + 'px';

    }
    style_on_the_fly(css);
}

export function sizes(){
    let values = [ws.slice(1), hs.slice(1)];
    let css = {
        '.w': {'width': 0},
        '.maxw': {'max-width': 0},
        '.minh': {'min-height': 1},
        '.h': {'height': 1},
        '.lh': {'line-height': 1},
        '.fs': {'width': 0, 'height': 1},
        '.hh': {'width': 1, 'height': 1},
        '.ww': {'width': 0, 'height': 0},
    };
    let newcss = {};

    for (let classname in css)
    {
        if (!css.hasOwnProperty(classname)) continue;

        for (let i = 0; i < values[0].length; i++)
        {
            let clsname = (i > 0) ? classname + (i+1).toString() : classname;
            newcss[clsname] = {};

            for (let propname in css[classname])
            {
                if (!css[classname].hasOwnProperty(propname)) continue;
                newcss[clsname][propname] = values[css[classname][propname]][i].toString() + 'px';
            }
        }
    }
    style_on_the_fly(newcss);
}



export function inputs(){
    let css = {
        'input[type="text"], textarea ': {height: hs[5] +'px', padding: '0 ' + ws[2]+'px'},
        'input[type="number"]': {height: hs[5] +'px'},
        'input[type="file"]': {height: hs[5] +'px'},
        select: {height: hs[5] +'px', width: ws[6] +'px'},
    };
    style_on_the_fly(css);

}
export function borders(){
    let css = {
        '.fbor1': {'border-style': 'solid', 'border-width': ws[0] + 'px ' + hs[0]+'px'},
        '.fbor': {'border-style': 'solid', 'border-width': fib(ws[0], -2) + 'px ' + fib(hs[0], -2)+'px'},
        // 'input[type="number"]': {height: hs[5] +'px'},
        // 'input[type="file"]': {height: hs[5] +'px'},
        // select: {height: hs[5] +'px', width: ws[6] +'px'},
    };
    style_on_the_fly(css);

}


export function fonts(){
    let xs = is_sm() ? [] : [fib(ws[0], -1), ...ws].splice(0, 6);
    let $p = $('<p>');
    let results = [];
    let letterspacings = [0, fib(ws[0], -2) + 'px',fib(ws[0], -3) + 'px',fib(ws[0], -4) + 'px',];


        for (var i = 0; i < letterspacings.length; i++) {
            $p
            .css({
                whiteSpace: 'nowrap',
                display: 'inline-block',
                'letter-spacing': letterspacings[i]})
            .html(lorem());
            results[i] = [];

            calc_avg_font_size(i, results[i]);

            // console.count(22)
        }





function calc_avg_font_size(index, res) {

    for (let i = 0;  i < xs.length;  i++) {
         let x = xs[i];
         let target = xs[i];
         let avg = 0;
         // results[i][index] = [];

         do{
             $body.append($p.css({fontSize: x++, letterSpacing: letterspacings[index]}));
             avg = Math.round($p.outerWidth()/lorem().length);

         }while(avg < target)

         res.push(x)


    }

    $p.remove();
}




    // console.log(results);





    // window.alert($p.outerWidth());
    // console.log($p.outerWidth()/a.lorem().length);




    let css = {
        body: {
            // 'line-height': hs[3] +'px',
            'line-height': 1,
            'font-size': results[0][1] +'px',
            // 'letter-spacing': fib(ws[0], -3) + 'px'
        },
        h1:{
            'font-size': results[0][5] +'px',
        },
        '.fontsize1': {'font-size': results[0][0] +'px'},
        '.fontsize2': {'font-size': results[0][1] +'px'},
        '.fontsize3': {'font-size': results[0][2] +'px'},
        '.fontsize4': {'font-size': results[0][3] +'px'},
        '.fontsize5': {'font-size': results[0][4] +'px'},
        '.fontsize6': {'font-size': results[0][5] +'px'},
        '.fontsize1-ls1': {'font-size': results[1][0] +'px', 'letter-spacing': letterspacings[1]},
        '.fontsize2-ls1': {'font-size': results[1][1] +'px', 'letter-spacing': letterspacings[1]},
        '.fontsize3-ls1': {'font-size': results[1][2] +'px', 'letter-spacing': letterspacings[1]},
        '.fontsize4-ls1': {'font-size': results[1][3] +'px', 'letter-spacing': letterspacings[1]},
        '.fontsize5-ls1': {'font-size': results[1][4] +'px', 'letter-spacing': letterspacings[1]},
        '.fontsize6-ls1': {'font-size': results[1][5] +'px', 'letter-spacing': letterspacings[1]},
        '.fontsize1-ls2': {'font-size': results[2][0] +'px', 'letter-spacing': letterspacings[2]},
        '.fontsize2-ls2': {'font-size': results[2][1] +'px', 'letter-spacing': letterspacings[2]},
        '.fontsize3-ls2': {'font-size': results[2][2] +'px', 'letter-spacing': letterspacings[2]},
        '.fontsize4-ls2': {'font-size': results[2][3] +'px', 'letter-spacing': letterspacings[2]},
        '.fontsize5-ls2': {'font-size': results[2][4] +'px', 'letter-spacing': letterspacings[2]},
        '.fontsize6-ls2': {'font-size': results[2][5] +'px', 'letter-spacing': letterspacings[2]},
        '.fontsize1-ls3': {'font-size': results[3][0] +'px', 'letter-spacing': letterspacings[3]},
        '.fontsize2-ls3': {'font-size': results[3][1] +'px', 'letter-spacing': letterspacings[3]},
        '.fontsize3-ls3': {'font-size': results[3][2] +'px', 'letter-spacing': letterspacings[3]},
        '.fontsize4-ls3': {'font-size': results[3][3] +'px', 'letter-spacing': letterspacings[3]},
        '.fontsize5-ls3': {'font-size': results[3][4] +'px', 'letter-spacing': letterspacings[3]},
        '.fontsize6-ls3': {'font-size': results[3][5] +'px', 'letter-spacing': letterspacings[3]},
    };


    // console.log('asd', results);

    style_on_the_fly(css);
}

export function round(number, axis = 'x', direction = 1){
    let arr = (axis === 'x') ? ws : hs;
    // number = 34;

    for (var i = 0; i < arr.length; i++) {
        let item = arr[i];
        if (item >= number) {
            if (direction === 1) {
                return item;
            }else if(direction === -1){
                return arr[i-1];
            }else if(direction === 0){
                let diff_a = number - arr[i-1];
                let diff_b = Math.abs(number - arr[i]);

                return (diff_a > diff_b) ? item : arr[i-1];
            }
        }
    }
}

export function fibonize(selector, direction) {
    let $elems = $(selector);

    for (var i = 0; i < $elems.length; i++) {
        let $elem = $elems.eq(i);
        let width = $elem.outerWidth();
        let height = $elem.outerHeight();


        $elem.outerHeight(round(height, 'y')).outerWidth(round(width));

        // console.log($elem, height, $elem.outerHeight());
    }
}


export {ws, hs, F, perspective};
